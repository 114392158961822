const setDebugCookie = () => {
  const date = new Date()

  // Set it expire in 0.5 days
  date.setTime(date.getTime() + 0.5 * 24 * 60 * 60 * 1000)

  // Set it
  document.cookie = `debug=true; expires=${date.toUTCString()}; path=/`
}
const getDebugCookie = () => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; debug=`)

  if (parts && parts.length === 2) {
    const poppedParts = parts.pop()
    return poppedParts && poppedParts.split(';').shift() === 'true' && true
  }
  return false
}
const deleteDebugCookie = () => {
  const date = new Date()

  // Set it expire in -1 days
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000)

  // Set it
  document.cookie = `debug=true; expires=${date.toUTCString()}; path=/`
}

export const debugFlag = ($window: Window) => {
  const urlParams = new URLSearchParams($window.location.search)

  urlParams.get('debug') !== 'true'
    ? urlParams.get('debug') === 'false'
      ? deleteDebugCookie()
      : ''
    : setDebugCookie()

  if (getDebugCookie()) {
    return true
  }

  return false
}
