import * as React from 'react'

import { Input as AbstractInput } from '@thg-commerce/gravity-elements'
import { InputProps } from '@thg-commerce/gravity-elements/FormItem/Input/Input'

import { eventLogger } from '../helpers/loggers'
import { typeAdder } from '../helpers/typeAdder'
import { GeneralTrackingProps, InputTrackingProps } from '../Types'

const defaultProps = {
  error: false,
  helperText: '',
  ltr: true,
  show: true,
  delete: true,
  type: 'text',
  labelHidden: false,
  required: false,
  autocomplete: 'off',
  confirmable: false,
  i18nText: {
    showLabel: 'SHOW',
    showAriaLabel: 'Show password',
    hideLabel: 'HIDE',
    hideAriaLabel: 'Hide password',
    clearAriaLabel: 'Clear field',
    showAnnouncementText: 'Showing password',
    hideAnnouncementText: 'Hiding Password',
    clearAnnouncementText: 'Cleared input',
  },
  validators: [],
}

const TrackedInput = typeAdder(AbstractInput)<
  InputProps & GeneralTrackingProps & InputTrackingProps
>``

export const InputEventsTracked = (props: InputProps) => {
  return (
    <TrackedInput
      {...props}
      onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
        eventLogger(e, 'Input')
        e.stopPropagation()
        e.preventDefault()
      }}
      onFocus={(e: React.SyntheticEvent<Element, Event>) => {
        eventLogger(e, 'Input')
        e.stopPropagation()
        e.preventDefault()
      }}
      onInput={(e: React.SyntheticEvent<Element, Event>) => {
        eventLogger(e, 'Input')
        e.stopPropagation()
        e.preventDefault()
        props.onInput && props.onInput(e)
      }}
      clearInputValueHandler={(e: React.SyntheticEvent<Element, Event>) => {
        eventLogger(e, 'InputClear')
        e.stopPropagation()
        e.preventDefault()
        props.clearInputValueHandler && props.clearInputValueHandler(e)
      }}
    />
  )
}

export const Input = (props: InputProps) => {
  return <InputEventsTracked {...props} />
}

Input.defaultProps = defaultProps

export default Input
