import * as React from 'react'

import { SocialButton as AbstractSocialButton } from '@thg-commerce/gravity-elements/SocialButton'
import { SocialButtonProps } from '@thg-commerce/gravity-elements/SocialButton/SocialButton'

import { eventLogger, inViewLogger } from '../helpers/loggers'
import { typeAdder } from '../helpers/typeAdder'
import { GeneralTrackingProps } from '../Types'
import { withIsVisible } from '../WithIsVisible'

const TrackedButton = typeAdder(AbstractSocialButton)<
  SocialButtonProps & GeneralTrackingProps
>``

export const SocialButtonEventsTracked = (
  props: SocialButtonProps & { isVisible?: boolean },
) => {
  inViewLogger(props.isVisible, 'SocialButton')
  return (
    <TrackedButton
      {...props}
      onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
        eventLogger(e, 'SocialButton')
        props.onClick && props.onClick(e)
      }}
      onMouseOver={(e: React.MouseEvent<Element, MouseEvent>) => {
        eventLogger(e, 'SocialButton')
        e.stopPropagation()
        e.preventDefault()
      }}
      onFocus={(e: React.SyntheticEvent<Element, Event>) => {
        eventLogger(e, 'SocialButton')
      }}
    />
  )
}

export const SocialButton = (props: SocialButtonProps) => {
  const WrappedComponent = withIsVisible(SocialButtonEventsTracked)
  // @ts-ignore
  return <WrappedComponent {...props} />
}

export default SocialButton
