import * as React from 'react'

import { useConfig } from '../ConfigurationLoader'

export const Flag = (props) => {
  const { assetPrefix } = useConfig()
  return (
    <img
      alt={`${props.flagIcon} flag`}
      style={{
        width: '24px',
        borderRadius: '50%',
        border: '1px solid white',
        marginRight: '8px',
      }}
      src={`${assetPrefix}/static/flags/1x1/${props.flagIcon.toLowerCase()}.svg`}
    />
  )
}
