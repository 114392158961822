import * as React from 'react'

import { Button as AbstractButton } from '@thg-commerce/gravity-elements'
import { ButtonProps } from '@thg-commerce/gravity-elements/Button/Button'

import { eventLogger, inViewLogger } from '../helpers/loggers'
import { typeAdder } from '../helpers/typeAdder'
import { GeneralTrackingProps } from '../Types'
import { withIsVisible } from '../WithIsVisible'

const TrackedButton = typeAdder(AbstractButton)<
  ButtonProps & GeneralTrackingProps
>``

export const ButtonEventsTracked = React.forwardRef(
  (props: ButtonProps & { isVisible? }, ref?: any) => {
    inViewLogger(props.isVisible, 'Button')
    return (
      <TrackedButton
        {...props}
        onClick={(e) => {
          eventLogger(e, 'Button')
          props.onClick && props.onClick(e)
        }}
        onMouseOver={(e) => {
          eventLogger(e, 'Button')
          e.stopPropagation()
        }}
        onFocus={(e) => {
          eventLogger(e, 'Button')
        }}
        ref={ref}
      />
    )
  },
)

export const Button = React.forwardRef((props: ButtonProps, ref?: any) => {
  const WrappedComponent = withIsVisible(ButtonEventsTracked)

  // @ts-ignore
  return <WrappedComponent {...props} ref={ref} />
})

export default Button
