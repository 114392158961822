import * as React from 'react'

import { RadioGroup as BaseRadioGroup } from '@thg-commerce/gravity-elements'
import { RadioGroupProps } from '@thg-commerce/gravity-elements/RadioGroup/RadioGroup'

import { eventLogger, inViewLogger } from '../helpers/loggers'
import { typeAdder } from '../helpers/typeAdder'
import { GeneralTrackingProps } from '../Types'
import { withIsVisible } from '../WithIsVisible'

const RadioGroupToTrack = typeAdder(BaseRadioGroup)<
  RadioGroupProps & GeneralTrackingProps
>``

export const RadioGroupEventsTracked = (
  props: RadioGroupProps & { isVisible?: boolean },
) => {
  inViewLogger(props.isVisible, 'RadioGroup')
  return (
    <RadioGroupToTrack
      {...props}
      inView={props.isVisible}
      onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
        eventLogger(e, 'RadioGroup')
        e.preventDefault()
      }}
      onMouseOver={(e: React.SyntheticEvent<Element, Event>) => {
        eventLogger(e, 'RadioGroup')
      }}
    />
  )
}

export const RadioGroup = (props: RadioGroupProps) => {
  const WrappedComponent = withIsVisible(RadioGroupEventsTracked)
  // @ts-ignore
  return <WrappedComponent {...props} />
}

export default RadioGroup
