import { spacing, styled } from '@thg-commerce/gravity-theme'

export const StyledButton = styled.button<
  React.HTMLProps<HTMLButtonElement> & { href?: string }
>`
  transition: 0.2s ease-in-out;
  box-sizing: content-box;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.18px;
  text-decoration: none;
  width: 100%;
  height: ${spacing(6)};
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-radius: 0px;
  color: ${(props) => props.theme.colors.palette.greys.dark};
  border-color: ${(props) => props.theme.colors.palette.greys.grey};
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg,
  img {
    margin-right: ${spacing(1)};
    max-height: ${spacing(3)};
  }

  &:hover {
    border-width: 1px;
    color: ${(props) => props.theme.colors.palette.greys.dark};
    border-color: ${(props) => props.theme.colors.palette.greys.lighter};
    background-color: ${(props) => props.theme.colors.palette.greys.lighter};
  }

  &:focus-visible {
    outline: none;
    border-width: 2px;
    border-color: ${(props) =>
      props.theme.button.low.regular.focus.borderColor};
    background-color: ${(props) => props.theme.colors.palette.greys.lighter};
    color: ${(props) => props.theme.colors.palette.greys.dark};
  }

  &:active {
    border-width: 1px;
    color: ${(props) => props.theme.colors.palette.greys.dark};
    border-color: ${(props) => props.theme.colors.palette.greys.light};
    background-color: ${(props) => props.theme.colors.palette.greys.light};
  }

  &:disabled {
    border-width: 1px;
    color: ${(props) => props.theme.colors.palette.greys.white};
    border-color: ${(props) => props.theme.colors.palette.greys.light};
    background-color: ${(props) => props.theme.colors.palette.greys.light};
  }
`
