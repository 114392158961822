import * as React from 'react'

// import { theme } from '@thg-commerce/gravity-theme'

// import { loadPolyfills } from '../helpers/polyfill'

// import { useInView } from 'react-intersection-observer'

export const withIsVisible = (Component: any) =>
  React.forwardRef((props, ref) => {
    // React.useEffect(() => {
    //   loadPolyfills()
    // })

    if (typeof Window !== 'undefined') {
      // const [ref, inView] = useInView({
      //   rootMargin: `-${theme.site.headerHeight}px`,
      // })

      // const inViewRef = ref

      return (
        <div /*ref={inViewRef}*/>
          <Component {...props} /*isVisible={inView}*/ />
        </div>
      )
    }

    return (
      <div>
        <Component {...props} ref={ref} />
      </div>
    )
  })
