import * as React from 'react'
import axios from 'axios'
import * as Cookies from 'js-cookie'
import { vsprintf } from 'sprintf-js'

import { ModalRenderer } from '@thg-commerce/enterprise-modal'
import { spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import { Button } from '@thg-commerce/groundstation-elements'

import {
  getSafeUrlParametersFromWindow,
  useI18n,
  useSiteDefinition,
} from '../../'
import { useHeaderHeight } from '../EnterpriseContext/hooks'

import { Flag } from './Flag'

const StyledHeading = styled.h1`
  ${Text('large1', 'alternate')};
  padding-top: ${spacing(3)};
`

const StyledBodyText = styled.div`
  ${Text('bodyText', 'default')};
  padding-top: ${spacing(3)};
`

export interface InternationalOverlayData {
  redirectDomain: string
  subsiteIdentifier: string
  currencyWithIcon: string
  flagIcon: string
  subSite: string
  languageCode: string
  countryCode: string
}

export const InternationalOverlay: React.FunctionComponent = () => {
  const { subsite, domain } = useSiteDefinition()
  const i18n = useI18n()
  const headerHeight = useHeaderHeight()

  const [data, setData] = React.useState<InternationalOverlayData | undefined>()
  const [display, setDisplay] = React.useState(false)

  React.useEffect(() => {
    if (
      Cookies.get(`${subsite}_chosenSubsite_V6`) === subsite ||
      getSafeUrlParametersFromWindow(window, 'countrySelected') === 'Y'
    ) {
      return
    }

    axios
      .get<InternationalOverlayData>('/e2/operation/international-overlay', {
        validateStatus: () => true,
        timeout: 1000,
      })
      .then((response) => {
        if (response.status !== 200 || !response.data) {
          return
        }
        setData(response.data)
        setDisplay(
          Cookies.get(`${subsite}_chosenSubsite_V6`) !== subsite &&
            getSafeUrlParametersFromWindow(window, 'countrySelected') !== 'Y',
        )
      })
      .catch(() => {
        // Fail silently
      })
  }, [])

  const stayOnSiteAction = (onClose: () => void) => {
    if (!data) {
      return
    }

    Cookies.set(`${subsite}_chosenSubsite_V6`, subsite, {
      expires: 3, // days
      secure: location.protocol.includes('https'),
      path: '/',
    })

    setDisplay(false)
    onClose()
  }

  const redirectAwayAction = (onClose: () => void) => {
    if (!data) {
      return
    }

    Cookies.set(`${subsite}_chosenSubsite_V6`, data.subsiteIdentifier, {
      expires: 3, // days
      secure: location.protocol.includes('https'),
    })

    setDisplay(false)
    onClose()

    window.location.href = `https://${data.redirectDomain}?country_selected=Y`
  }

  return display && data ? (
    <ModalRenderer
      removeFocusTrap={true} // Removed because cookie modal might also show
      data-testid="international-overlay"
      gridColSpan={[12, 8, 6, 4]}
      headerOffset={headerHeight}
      closeI18nText={{
        closeAriaLabel: i18n('general.modal.close.button.arialabel'),
        closeLabel: i18n('general.modal.close.button.label'),
      }}
      onClose={async () => {
        stayOnSiteAction(() => {})
        return true
      }}
      renderContent={(onClose) => {
        return (
          <React.Fragment>
            <StyledHeading>
              {i18n(`internationalredirect.heading.${data.languageCode}.text`)}
            </StyledHeading>
            {/* We have to use this to be able to render all Currency characters correctly */}
            <StyledBodyText
              dangerouslySetInnerHTML={{
                __html: vsprintf('%s %s%s%s %s %s %s', [
                  i18n(
                    `internationalredirect.precountryexplanation.${data.languageCode}.text`,
                  ),
                  i18n(`header.country.${data.countryCode}.text`),
                  i18n(
                    `internationalredirect.postcountryexplanation.${data.languageCode}.text`,
                  ),
                  i18n(`header.country.identifier.${data.countryCode}.text`),
                  i18n(
                    `internationalredirect.precurrencyexplanation.${data.languageCode}.text`,
                  ),
                  data.currencyWithIcon,
                  i18n(
                    `internationalredirect.postcurrencyexplanation.${data.languageCode}.text`,
                  ),
                ]),
              }}
            />
            <Button
              style={{
                width: '100%',
                marginTop: spacing(3),
              }}
              onClick={() => redirectAwayAction(onClose)}
            >
              <Flag flagIcon={data.flagIcon} />
              {i18n(
                `internationalredirect.gobuttontext.${data.languageCode}.text`,
              )}{' '}
              {data.redirectDomain.toUpperCase()}
            </Button>

            <Button
              style={{
                height: spacing(6),
                marginTop: spacing(2),
                textAlign: 'left',
              }}
              emphasis={'low'}
              onClick={() => stayOnSiteAction(onClose)}
            >
              {typeof window !== 'undefined' &&
                `${i18n(
                  'general.internationaloverlay.staybutton.text',
                )} ${domain.toUpperCase()}`}
            </Button>
          </React.Fragment>
        )
      }}
    />
  ) : null
}

export default InternationalOverlay
