import { debugFlag } from './debug'
export const eventLogger = (event, propName) => {
  typeof window !== 'undefined' &&
    debugFlag(window) &&
    console.info(
      `%c ${event && event.nativeEvent.type} %cEvent on ${propName} `,
      `background: ${TrackingColours[propName]}; color: white; padding: 2px 0px;font-style: italic`,
      `background: ${TrackingColours[propName]}; color: white; padding: 2px 0px`,
      event,
    )
}
export const inViewLogger = (inView, propName) => {
  typeof window !== 'undefined' &&
    debugFlag(window) &&
    console.info(
      `%c ${propName} %cis ${inView ? 'in view ' : 'out of view '}`,
      `background: ${TrackingColours[propName]}; color: white; padding: 2px 0px;font-style: italic`,
      `background: ${TrackingColours[propName]}; color: white; padding: 2px 0px`,
    )
}

const TrackingColours = {
  Button: '#B1391A',
  SocialButton: '#E4A834',
  Input: '#832C76',
  InputClear: '#832C76',
  RadioGroup: '#3385A3',
}
