import * as React from 'react'
import loadable from '@loadable/component'

import { ThemeInterface } from '@thg-commerce/gravity-theme'
const Facebook = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Facebook'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Google = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Google'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Twitter = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Twitter'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const WeChat = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/WeChat'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Weibo = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Weibo'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

import { StyledButton } from './Styles'

export enum SocialButtonType {
  Facebook = 'facebook',
  Twitter = 'twitter',
  Google = 'google',
  WeChat = 'wechat',
  Weibo = 'weibo',
}

export interface SocialButtonProps {
  theme?: ThemeInterface
  disabled?: boolean | undefined
  style?: React.CSSProperties
  socialtype?: SocialButtonType
  onClick?: (event: React.MouseEvent) => void
  displayName: string
  url: string
  iconUrl?: string
  i18nText?: string
}

/** @component */
export const SocialButton = (props: SocialButtonProps) => {
  const { displayName, url, iconUrl, socialtype, i18nText } = props

  const displayText = i18nText || displayName

  const RenderIcon = (socialtype?: SocialButtonType, icon?: any) => {
    switch (socialtype && socialtype.toLowerCase()) {
      case 'facebook':
        return <Facebook data-testid="social-button-icon" />
      case 'twitter':
        return <Twitter data-testid="social-button-icon" />
      case 'google':
        return <Google data-testid="social-button-icon" />
      case 'wechat':
        return <WeChat data-testid="social-button-icon" />
      case 'weibo':
        return <Weibo data-testid="social-button-icon" />
    }
    return (
      <img
        src={icon}
        alt={`Login with ${displayName}`}
        data-testid="social-button-icon"
      />
    )
  }

  return (
    <StyledButton as="a" href={url} type="button" {...props}>
      {RenderIcon(socialtype, iconUrl)}
      {displayText}
    </StyledButton>
  )
}

export default SocialButton
